// External dependencies
import { Div, Text } from 'atomize';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

// Local dependencies
import './style.scss';

interface InfoBlockProps {
  backgroundColor: string;
  image: any;
  flexDir: string;
  title: any;
  subtitle: any;
  paddingForImageInAbout?: string;
}

export default function InfoBlock({
  backgroundColor,
  image,
  title,
  paddingForImageInAbout,
  subtitle,
  flexDir,
}: InfoBlockProps) {
  return (
    <Div
      overflow="hidden"
      bg={backgroundColor}
      rounded="40px"
      d="flex"
      flexDir={{ xs: 'column-reverse', md: flexDir }}
      align="center"
      p={{
        r: paddingForImageInAbout,
        t: { xs: '25px', sm: '0px' },
        l: flexDir === 'row-reverse' && { xs: '0', md: '50px' },
      }}
      m={{ b: '20px' }}
      style={{ gap: '40px' }}
      data-aos="fade-up"
      data-aos-duration="300"
    >
      <Div
        m="0 auto"
        style={{ flex: '1 1 50%' }}
        data-aos="zoom-in"
        data-aos-delay="300"
        p={{ l: '20px', r: { md: '0px' } }}
      >
        <GatsbyImage
          className={`${
            paddingForImageInAbout === 'imgForDoctor' ? 'imgForDoctor' : ''
          }`}
          style={{ maxHeight: '400px' }}
          image={image}
          alt="image"
          objectFit="contain"
        />
      </Div>
      <Div
        p={{ xs: '50px', md: '0' }}
        style={{ flex: '1 1 50%', justifySelf: 'center' }}
      >
        <Div>
          <Text
            textSize={{ xs: 'display2', sm: 'subtitle' }}
            textWeight="infoBlockTitle"
            textColor="text_color_for_teaser"
            tag="h2"
            m={{ b: '24px' }}
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {title}
          </Text>
          <Text
            tag="p"
            textColor="text_color_for_teaser"
            textSize={{ xs: 'smallSize2', sm: 'medium' }}
            textWeight="infoBlockSubTitle"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {subtitle}
          </Text>
        </Div>
      </Div>
    </Div>
  );
}
