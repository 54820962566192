// External dependencies
import { Container, Div, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import WhatsAppIcon from '../../../svg/WhatsAppIcon';
import SmailIconLeft from '../../../svg/smailIconLeft';
import SmailIconRight from '../../../svg/smailIconRight';
import ExternalButtonLink from '../externalButtonLink/ExternalButtonLink';
import './style.scss';

interface SecondaryHeroProps {
  bgColor: string;
  gradient?: boolean;
  smileIcons?: boolean;
  textColor: string;
  classNameForSecondaryHero?: string;
}

export default function SecondaryHero({
  bgColor,
  gradient,
  smileIcons,
  textColor,
  classNameForSecondaryHero,
}: SecondaryHeroProps) {
  function sendEventToAnalytic() {
    if (typeof window !== undefined && process.env.NODE_ENV === 'production') {
      window.gtag('event', 'get_in_touch', {
        event_name: 'get_in_touch',
      });
    }
  }

  return (
    <Div
      bg={bgColor}
      d="flex"
      flexDir="column"
      justify="center"
      align="center"
      pos="relative"
      overflow="hidden"
      minH={{ xs: '300px', md: '550px' }}
      m={{ b: { xs: '50px', sm: '70px', md: '90px', lg: '100px' } }}
      p={{ t: '160px', b: '100px' }}
    >
      <Container>
        {gradient && (
          <>
            <Div
              pos="absolute"
              w="413px"
              h="413px"
              right="-200px"
              bottom="-200px"
              className="linearGradientRight"
            ></Div>
            <Div
              pos="absolute"
              h="413px"
              w="413px"
              left="-200px"
              top="-200px"
              className="linearGradientLeft"
            ></Div>
          </>
        )}
        <Div
          m="0 auto"
          p={{ b: '32px', t: '50px' }}
          textSize={{
            xs: 'heading',
            md: 'subtitle',
            lg: 'title',
          }}
          textWeight="700"
          textColor={textColor}
          textAlign={{ xs: 'start', lg: 'center' }}
          pos="relative"
        >
          <Div pos="relative">
            {smileIcons && (
              <>
                <Div
                  d={{ xs: 'none', lg: 'block' }}
                  pos="absolute"
                  bottom="-69px"
                  left={{ lg: '200px', xl: '183px' }}
                  transform="rotate(-20deg)"
                  className="smailIconLeft"
                >
                  <SmailIconLeft />
                </Div>
                <Div
                  d={{ xs: 'none', lg: 'block' }}
                  pos="absolute"
                  top="-40px"
                  right={{ lg: '40px', xl: '115px' }}
                  className="smailIconRight"
                >
                  <SmailIconRight />
                </Div>
              </>
            )}
            <Text
              m={{ b: '23px', x: { lg: 'auto' } }}
              tag="h3"
              textSize={{ xs: 'heading', sm: 'title' }}
            >
              <Text tag="span" className={classNameForSecondaryHero}>
                <Trans>increaseRecognitionText</Trans>
              </Text>
              <Trans>increaseRecognitionOfYourInstitution</Trans>
            </Text>
            <Div
              m={{ lg: '0 auto' }}
              p={{ t: '32px' }}
              data-aos="zoom-in"
              data-aos-delay="700"
              textSize={{ xs: 'smallSize2', lg: 'medium' }}
              textWeight="500"
              textAlign={{ xs: 'start', lg: 'center' }}
              textColor={textColor}
              maxW="590px"
              d="flex"
              justfy="center"
              flexDir="column"
            >
              <Text tag="p" textSize={{ xs: 'display1', sm: 'medium' }}>
                <Trans>mobileAppMottoText</Trans>
              </Text>
              <Div
                w={{ xs: '100%', sm: '343px', lg: '360px' }}
                m={{ lg: '0 auto' }}
                p={{ t: '32px' }}
                onClick={sendEventToAnalytic}
              >
                <ExternalButtonLink
                  bg="primary"
                  textColor="white"
                  link="https://wa.me/+996501501279"
                  target="__blank"
                  text={
                    <Div d="flex" justify="center" style={{ gap: '8px' }}>
                      <WhatsAppIcon /> <Trans>contactUs</Trans>
                    </Div>
                  }
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Container>
    </Div>
  );
}
