// External dependencis
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Layout from '../../shared/ui/layout/WebLayout';

// Local dependencies
import ProPackage from '../../components/website/proPackage/ProPackage';
import SEO from '../../shared/ui/layout/seo';

export default function ProPackagePage({ data }) {
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = data;
  const { t } = useI18next();

  return (
    <Layout>
      <SEO
        title={t('proPackagePageTitle')}
        description={t('proPackagePageContent')}
      />
      <ProPackage />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
