// External dependencies
import { Container, Div } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependencies
import DownloadApp from '../downloadApp/DownloadApp';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import InfoBlock from '../infoBlock/InfoBlock';
import { InfoCardProps } from '../infoCard/InfoCard';
import InfoCards from '../infoCard/InfoCards';
import SecondaryHero from '../secondaryHero/SecondaryHero';

export default function ProPackage() {
  const data = useStaticQuery(query);
  const infoBlockData = [
    {
      backgroundColor: 'main_bg_color',
      title: <Trans>interviewWithDoctors</Trans>,
      subtitle: <Trans>ourVideosText</Trans>,
      image: getImage(data?.doctorMan),
      flexDir: 'row',
    },
    {
      backgroundColor:
        'linear-gradient(90deg, rgba(140, 250, 199, 0.3) 0%, rgba(207, 214, 250, 0.3) 100%, rgba(207, 214, 250, 0.3) 100%)',
      title: <Trans>interviewWithPatience</Trans>,
      subtitle: <Trans>ourVideosWithPatience</Trans>,
      image: getImage(data?.doctorBald),
      flexDir: 'row-reverse',
    },
  ];
  const infoCardsData: InfoCardProps[] = [
    {
      children: <GatsbyImage image={getImage(data?.equipment)} />,
      imgHeight: '50%',
      height: '40%',
      title: <Trans>equipmentShooting</Trans>,
      text: <Trans>shooting</Trans>,
    },
    {
      children: (
        <Div
          m={{ t: { lg: '-35px' } }}
          shodow="10"
          d="flex"
          style={{ boxShadow: '-18px -5px 110px -77px rgba(0,0,0,0.75)' }}
          align="center"
          justify="center"
          bg="white"
          rounded="50px"
          p={{
            x: { xs: '20px', sm: '70px' },
            b: { xs: '15px', sm: '40px' },
            t: { xs: '20px', sm: '50px' },
          }}
        >
          <GatsbyImage
            style={{ width: '200px', height: '180px' }}
            image={getImage(data?.photoShoot)}
            objectFit="contain"
          />
        </Div>
      ),
      imgHeight: '50%',
      height: '40%',
      title: <Trans>photoShoot</Trans>,
      text: <Trans>photoShootText</Trans>,
    },
  ];

  return (
    <>
      <Header />
      <SecondaryHero
        bgColor="text_color_for_teaser"
        gradient
        smileIcons
        textColor="white"
        classNameForSecondaryHero="text_with_gradient"
      />
      <Container>
        {infoBlockData &&
          infoBlockData.map((block, index) => (
            <InfoBlock
              key={index}
              backgroundColor={block.backgroundColor}
              title={block.title}
              subtitle={block.subtitle}
              paddingForImageInAbout={index === 1 ? 'imgForDoctor' : '0px'}
              image={block.image}
              flexDir={block.flexDir}
            />
          ))}
        <InfoCards data={infoCardsData} />
      </Container>
      <SecondaryHero
        bgColor="white"
        classNameForSecondaryHero="text_with_no_gradient"
      />
      <Container>
        <DownloadApp />
      </Container>
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    doctorMan: file(relativePath: { eq: "doctor-man.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    doctorBald: file(relativePath: { eq: "doctor-bald.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    equipment: file(relativePath: { eq: "equipment.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    photoShoot: file(relativePath: { eq: "photo-shoot.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
