//External dependencies
import { Div, Text } from 'atomize';
import React from 'react';

// Local dependenciest
import './style.scss';

export interface InfoCardProps {
  children: any;
  bgColor?: string;
  height: string;
  imgHeight: string;
  title: any;
  text: any;
  titleColor?: string;
  textColor?: string;
}

export default function InfoCard({
  children,
  bgColor,
  imgHeight,
  height,
  title,
  text,
  titleColor,
  textColor,
}: InfoCardProps) {
  return (
    <Div
      w={{ xs: '100%', lg: '50%' }}
      rounded="40px"
      bg={bgColor ? bgColor : 'main_bg_color'}
      d="flex"
      flexDir="column"
      p={{ xs: '20px', sm: '50px' }}
      style={{ gap: '10px' }}
      data-aos="fade-up"
      overflow="hidden"
    >
      <Div
        h={imgHeight}
        className="info-card-children"
        m={{ b: { md: '43px' }, t: { xs: '20px', md: '0px' } }}
        d="flex"
        align="center"
        justify="center"
        data-aos="zoom-in"
      >
        {children}
      </Div>
      <Div h={height}>
        <Text
          className="info-card-title"
          textColor={titleColor ? titleColor : 'text_color_for_teaser'}
          textSize={{ xs: 'display', sm: 'subtitle' }}
          p={{ b: '24px' }}
          tag="h3"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {title}
        </Text>
        <Text
          className="info-card-text"
          textColor={textColor ? textColor : 'text_color_for_teaser'}
          textSize={{ xs: 'body', sm: 'medium' }}
          tag="p"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          {text}
        </Text>
      </Div>
    </Div>
  );
}
