//External dependencies
import { Div } from 'atomize';
import React from 'react';

// Local dependencies
import InfoCard, { InfoCardProps } from './InfoCard';

interface InfoCardsProps {
  data: InfoCardProps[];
  margin?: object | string;
}

export default function InfoCards({
  data,
  margin = { b: { xs: '50px', sm: '80px', md: '100px' } },
}: InfoCardsProps) {
  return (
    <Div
      d="flex"
      style={{ gap: '20px' }}
      flexDir={{ xs: 'column', lg: 'row' }}
      m={margin}
    >
      {data?.map((item, index) => (
        <InfoCard
          key={index}
          bgColor={item.bgColor}
          imgHeight={item.imgHeight}
          height={item.height}
          title={item.title}
          text={item.text}
          textColor={item.textColor}
          titleColor={item.titleColor}
        >
          {item.children}
        </InfoCard>
      ))}
    </Div>
  );
}
