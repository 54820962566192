// External dependensies
import { Div } from 'atomize';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';

interface Props {
  link?: string;
  target: string;
  text: any;
  textColor?: string;
  bg?: string;
  hoverBg?: string;
}

export default function ExternalButtonLink({
  bg = 'btn_hover_border_white',
  hoverBg = 'btn_hover_border_primary',
  link,
  target,
  text,
  textColor = 'white',
}: Props) {
  return (
    <OutboundLink href={link} target={target}>
      <Div
        textAlign="center"
        bg={bg}
        hoverBg={hoverBg}
        textColor={textColor}
        p="10px"
        rounded="10px"
        textSize={{ xs: 'body', sm: 'smallSize2' }}
        textWeight="600"
      >
        {text}
      </Div>
    </OutboundLink>
  );
}
