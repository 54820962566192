// External dependencies
import { Col, Div, Row, Text } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

// Local dependencies
import { Trans } from 'gatsby-plugin-react-i18next';
import DownloadAppButtons, {
  DownloadAppButton,
} from '../downloadAppButtons/DownloadAppButtons';
import DownloadAppQRCode from '../downloadAppQRCode/DownloadAppQRCode';

export default function DownloadApp() {
  const data = useStaticQuery(query);
  const imageMobile = getImage(data?.download_app_mobile);
  const app: DownloadAppButton[] = [
    {
      store: 'android',
      link: 'https://play.google.com/store/apps/details?id=kg.medcheck.app&hl=ru',
    },
    {
      store: 'apple',
      link: 'https://apps.apple.com/kg/app/medcheck/id1578873351',
    },
  ];

  return (
    <Row
      p={{ y: '24px', x: { xs: '10px', sm: '40px', md: '60px' } }}
      bg="bg_download_app"
      rounded="40px"
      align="center"
      m={{ b: '20px' }}
      data-aos="fade-up"
    >
      <Col size={{ xs: '12', md: '6' }}>
        <Div p={{ t: '39px', b: '30px' }}>
          <Div
            m={{ b: '30px' }}
            data-aos="fade-right"
            data-aos-delay="100"
            w={{ xs: '150px', sm: '200px' }}
          >
            <DownloadAppQRCode />
          </Div>
          <Text
            textColor="text_color_for_teaser"
            tag="h2"
            textSize={{ xs: 'display', sm: 'downloadAppTitle' }}
            textWeight="downloadAppTitle"
            m={{ b: '24px' }}
            data-aos="fade-right"
          >
            <Trans>downloadTheApp</Trans>
          </Text>
          <Text
            textColor="text_color_for_teaser"
            tag="p"
            textSize={{ xs: 'body', sm: 'display1' }}
            textWeight="medium"
            m={{ b: '24px' }}
            data-aos="fade-right"
          >
            <Trans>downloadAppDescription</Trans>
          </Text>
          <Div
            d="flex"
            style={{ gap: '5px' }}
            maxW="360px"
            className="downoadAppComponentsButtons"
          >
            {app?.map((item, index) => (
              <Div
                style={{ flex: '0 0 50%' }}
                key={index}
                w="100%"
                data-aos="fade-right"
              >
                <DownloadAppButtons store={item.store} link={item.link} />
              </Div>
            ))}
          </Div>
        </Div>
      </Col>
      <Col size={{ xs: '12', md: '6' }} data-aos="fade-left">
        <GatsbyImage
          image={imageMobile}
          alt="downloadmobile"
          objectFit="cover"
        />
      </Col>
    </Row>
  );
}

const query = graphql`
  query {
    download_app_mobile: file(relativePath: { eq: "medcheckInPhone.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
